.App, .page-template {
  margin: 2rem 2rem 1.2rem 2rem;
  font-family: 'Atkinson Hyperlegible', sans-serif;
}

.page-template {
  margin-top: 0;
}


/* tablet */
/* @media only screen and (max-width: 768px) {
  .App {
    margin: 1rem;
  }
} */

/* mobile  */
@media only screen and (max-width: 576px) {
  .App, .page-template {
    margin: 0;
  }
}
