body, html {
  width: 100%;
  height: auto;
  text-align: left;
  float: left;
  color: #333 !important;
  cursor: url('cursor.png'), auto;
  font-family: 'Atkinson Hyperlegible', sans-serif;
  font-size: 18px;
  position: relative;
  padding: 1rem;
}

h1, h1 a {
  font-size: 64px;
  font-weight: 700;
  font-style: italic;
  color: #9abcd6;
  border-bottom-style: none;
  text-decoration: none;
  font-family: 'Roboto Mono', monospace;
}

h2 {
  font-size: 42px !important;
}

h3 {
  font-size: 30px !important;
  margin-top: 2rem !important;
}

h5 {
  font-size: 24px !important;
  margin-bottom: .5rem !important;
}

.mainCol {
  max-width: 1000px;
}

.indexer {
  font-style: italic;
  color: #888;
  margin-bottom: 0;
}

.header-wrapper {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}

.header-link {
  font-family: 'Atkinson Hyperlegible', sans-serif;
  font-size: 18px;
  color: #888;
  margin: 0 .5rem 0 .5rem;
  text-decoration: none
}

.page-header a {
  font-family: "Roboto Mono", monospace;
  font-style: italic;
  font-weight: bold;
  color: #9abcd6;
  text-decoration: none;
  font-size: 24px;
  margin-right: 2rem;
}

blockquote {
  font-style: italic;
  border-left: 3px solid #CCC;
  padding-left: 1rem;
}

p {
  color: #333;
}

p a, .links-wrapper a, .footer a, .link-router {
  color: #7aa3c2;
  text-decoration: none !important;
}

.bib-pre {
  margin-bottom: 0 !important;
  padding: 1rem;
}

a:hover, button:hover {
  cursor: url('cursor-hover.png'), auto !important;
  color: #006800 !important;
}

:active {
  cursor: url('cursor-onclick.png'), auto;
}

a:active {
  cursor: url('cursor-onclick.png'), auto;
}

button {
  background-color: transparent;
  border-color: transparent;
}

button:focus {
  outline:0;
}

.links-wrapper img {
  width: 100%;
  padding-bottom: 0;
}

.bio-wrapper {
  padding-bottom: 1rem;
  max-width: 800px !important;
}

.navigator {
  margin-right: 3rem;
  color: #888;
  font-family: 'Roboto Mono', monospace;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.nav-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0rem 0 1rem 0;
}

.links-wrapper {
  font-family: 'Roboto Mono', monospace;
  color: #333;
}

.showcase-wrapper {
  display: flex;
  flex-flow: row wrap;
}

.showcase {
  width: 16rem;
  margin-right: 1rem;
  margin-bottom: 1rem !important;
}

.showcase:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.showcase-info {
  bottom: 1rem;
  position: absolute;
}

.active {
  color: #333;
  border-bottom: 0.15rem solid;
}

.venue-info {
  margin: .5rem 0 5rem 0;
  font-style: italic;
  color: #888;
}

.venue-page {
  margin-bottom: 2rem;
  font-style: italic;
}

.showcase-link {
  font-size: 0.85rem;
  text-decoration: none;
  color: #7aa3c2;
  font-family: 'Roboto Mono', monospace;
  margin-right: 1rem;
  display: inline-block;
  padding: 0;
}

.card-group {
  border-left: 100 !important;
}

.authorpile {
  margin-top: 2rem !important;
  margin-bottom: 0.5rem;
}

.author-block {
  margin-bottom: 1rem;
}

.circle-pic {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  /* background-image: url('./assets/img/people/kjfeng.png'); */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
}

.author-name {
  display: inline-block;
  vertical-align: middle;
}

.video-container {
  margin: 0 auto;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.pub-wrapper {
  margin-bottom: 1.5rem;
}

.bib-block {
  background-color: #eee;
  margin-top: 1rem;
  position: relative;
  border-left: 3px solid #aaa;
}

.links-block {
  background-color: #eee;
  border-left: 3px solid #aaa;
  overflow: wrap;
  padding: 1rem 0 1rem 0;
  font-size: 0.85rem;
}

.icon-wrapper {
  width: 50px;
}

.bio-icon {
  display: table;
  margin: 0 auto;
}

.copy-button {
  color: #7aa3c2;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.thumbnail {
  width: 100%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.thumbnail:hover {
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2);
}

figcaption {
  padding-bottom: 1rem;
  color: #888;
  font-style: italic;
  text-align: center;
}

#top-button {
  display: none;
  position: fixed;
  bottom: 30px;
  right: 40px;
  z-index: 99;
  border: none;
  outline: none;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  color: #333;
}

#top-button:hover {
  cursor: url('cursor-hover.png'), auto;
  color: #006800;
}

.top-text {
  font-size: 12px;
}

.footer {
  /* font-size: 16px; */
  margin-top: 2rem;
  font-style: italic;
  color: #888;
}

@media only screen and (max-width: 576px) {
  .showcase {
    width: 100%;
    margin-right: 0
  }

  body {
    padding: 0;
  }

  h1, h1 a {
    font-size: 48px;
  }
}
